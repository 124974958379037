
	import ModalComponentBase from "@/shared/component-base/modal-component-base";
	import {Dic} from "@/shared/utils";
	import {
		CreateOrUpdateHolidayRuleInput, HolidayRuleEditDto,
		HolidayRuleListDto,
		HolidayRuleServiceProxy,
		WorkYearsQuotaEditDto
	} from "@/shared";
	import ObjectUtils from "@/shared/utils/ObjectUtils";
	import moment from "moment";

	export default {
		name: "create-or-edit-holiday-rules",
		mixins: [ModalComponentBase],
		data() {
			return {
				_holidayRuleServiceProxy: undefined,


				id: undefined,
				companyAgeLoop: [{
					text: '司龄<1年',
					value: 1.0
				}],
				//字典-有效期
				dicValidPeriod: [],
				//字典-单位
				dicHolidayUnit: [],
				//发放方式
				dicAssignType: [],
				//发放日期方式
				dicAssignDateType: [],
				//额度值规则
				dicQuotaRuleType: [],

				model: new HolidayRuleEditDto(),

                //发放日期月 从0开始
                issueMonth: 0,
                //发放日期日 从1开始
                issueDay: 1
			}
		},
		computed: {
			IssueMonths(){
				let arr = [];
				for(let i = 0; i < 12; i++){
					arr.push({
                        title: (i+1)+'月',
                        value: i
                    })
                }
				return arr;
            },
            IssueDays(){
				return [31,28,31,30,31,30,31,31,30,31,30,31];
            }
        },
		async created() {
			this.fullData();
			this._holidayRuleServiceProxy = new HolidayRuleServiceProxy(this.$apiUrl, this.$api);
			this.loading = true;
			try {
				this.dicValidPeriod = await Dic.getInstance().getDicAsync('ValidPeriod');
				this.dicHolidayUnit = await Dic.getInstance().getDicAsync('HolidayUnitType');
				this.dicAssignType = await Dic.getInstance().getDicAsync('AssignType');
				this.dicAssignDateType = await Dic.getInstance().getDicAsync('AssignDateType');
				this.dicQuotaRuleType = await Dic.getInstance().getDicAsync('QuotaRuleType');
				//额度值规则 默认《固定值》
				this.model.quotaRuleType = 'FixedValue';
				//有效期 默认《按发放日起一周年》
				this.model.validPeriod = 'Anniversary';
				if (this.id) {
					const response = await this._holidayRuleServiceProxy.getForEdit(this.id);
					this.model = ObjectUtils.initDto(response.holidayRule);
					if(this.model.assignDate){
                        this.issueDay = this.model.assignDate.get('dates');
                        this.issueMonth = this.model.assignDate.get('months');
                    }
					if(response.workYearsList.length > 0){
						this.companyAgeLoop = [];
                    }
					for(let i = 0; i < response.workYearsList.length; i++){
						if(response.workYearsList[i].workYears == 0){
							this.companyAgeLoop.push({
                                id: response.workYearsList[i].id,
								holidayRuleId: response.workYearsList[i].holidayRuleId,
								text: `司龄<1年`,
								//取上一次输入的值
								value: response.workYearsList[i].quotaValue
							})
                        }else{
							this.companyAgeLoop.push({
								id: response.workYearsList[i].id,
								holidayRuleId: response.workYearsList[i].holidayRuleId,
								text: `司龄≥${response.workYearsList[i].workYears}年`,
								//取上一次输入的值
								value: response.workYearsList[i].quotaValue
							})
                        }
                    }
				}
			} catch (e) {
				console.error(e)
			} finally {
				this.loading = false;
			}

		},
		methods: {
			//检查表单
			checkForm(){
				return new Promise(resolve => {
					if(!this.model.name){
						abp.message.warn('请输入名称');
						resolve(false);
                    }else if(!this.model.code){
						abp.message.warn('请输入编码');
						resolve(false);
                    }else if(!this.model.minUnitNum){
						abp.message.warn('请输入最小请假单位');
						resolve(false);
                    }else if(this.model.isQuotaRule){
						if(!this.model.assignType){
							abp.message.warn('请选择发放方式');
							resolve(false);
                        }else if(!this.model.assignDateType){
							abp.message.warn('请选择发放日期方式');
							resolve(false);
                        }else if(this.model.quotaRuleType == 'FixedValue'){
							if(!this.model.quotaValue){
								abp.message.warn('请输入额度值');
								resolve(false);
                            }
                        }
                    }
					resolve(true);
                })
            },
            //保存表单
			async save() {
				if(!(await this.checkForm())){
					return;
                }
				this.saving = true;
				this.loading = true;
                try{
	                let entity = {
		                holidayRule: {
			                quotaRuleType: '',
			                assignDate: undefined
		                },
		                workYearsList: []
	                };
	                entity.holidayRule = {...this.model};
	                entity.holidayRule.assignDate = moment(`2020/${(this.issueMonth+1)}/${this.issueDay}`)
	                if (entity.holidayRule.quotaRuleType === 'WorkAge') {
		                entity.workYearsList = [];

		                for (let i = 0; i < this.companyAgeLoop.length; i++) {
			                let workYearDto = new WorkYearsQuotaEditDto();
			                workYearDto.id = this.companyAgeLoop[i].id;
			                workYearDto.holidayRuleId = this.companyAgeLoop[i].holidayRuleId;
			                workYearDto.workYears = i;
			                workYearDto.quotaValue = this.companyAgeLoop[i].value;
			                entity.workYearsList.push(workYearDto)
		                }
	                }
	                await this._holidayRuleServiceProxy.createOrUpdate(entity);

                    this.saving = false;
                    this.$notification["success"]({
                        message: this.l("SavedSuccessfully")
                    });
                    this.success(true);
                }catch (e) {
	                console.error(e)
                    this.saving = false;
                    this.loading = false;
                }
			},
			assignDateTypeChange(e){
				this.model.assignDateType = e;
            },
			quotaRuleTypeChange(e){
				this.model.quotaRuleType = e;
				//sb ui框架
				this.$forceUpdate();
            },
            //发放日期月改变
			issueMonthChange(e){
				this.issueMonth = e;
            },
            //发放日期日改变
			issueDayChange(e){
				this.issueDay = e;
            },
			//添加一行司龄配额
			addCompanyAge() {
				this.companyAgeLoop.push({
                    id: undefined,
					holidayRuleId: this.id,
					text: `司龄≥${this.companyAgeLoop.length}年`,
					//取上一次输入的值
					value: this.companyAgeLoop[this.companyAgeLoop.length - 1].value
				})
			},
			//移除一行司龄配额
			removeCompanyAge(index) {
				this.companyAgeLoop.splice(index, 1);
			},
		}
	}
